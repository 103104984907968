import PasswordPage from "./PasswordPage";
import PrivacyStatementModal from "./PrivacyStatementModal";
import SuccessPage from "./SuccessPage";

export const translations = {
    en: {
        WelcomePage: {
            "subHeader": "Let’s reset your password.",
            "bodyText": "You’ll need to know your username to reset your password.",
            "forgotPassword": "Forgot your username?",
            "Begin": "Begin",
            "Exit": "Exit"
        },
        AuthenticationPage: {
            "header": "Reset your Password",
            "subHeader": "Enter your information below.",
            "username": "Username",
            "enterUsername": "Please enter your username.",
            "userNamePlaceholder": "Enter your username",
            "forgotUsername": "Forgot your username?",
            "dateOfBirth": "Date of Birth",
            "dateOfBirthPlaceholder": "mm/dd",
            "validationMessage": "Please enter a valid date of birth in the following format: mm/dd.",
            "privacyStatement": "Privacy Statement",
            "submit": "Submit",
        },
        ServiceCenterPage: {
            "subHeader": "Call us to reset your password.",
            "bodyText": "You do not have a verification method on file.",
            "serviceCenter": "Covered California Service Center",
            "phoneNumber": "(855) 708-4590",
            "serviceHours": "Service Hours",
            "extendedHours": "Extended Hours",
            "Exit": "Exit"
        },
        OtpTypePage: {
            "subHeader": "Receive One-time Passcode",
            "bodyText": "To verify your identity, how would you like to receive your one-time passcode?",
            "email": "To your email:",
            "phone": "To your cell phone:",
            "submit": "Submit",
            "validationMessage": " You must make a selection to continue.",
            "Note": "*Standard text message rates apply.",
            "token": " Use expired token",
            "Submit": "Submit",
        },
        PasscodePage: {
            "subHeader": "Verify your Identity",
            "bodyText": "We sent a one-time passcode to your email.",
            "enterPasscode": "Enter One-time Passcode",
            "validationMessage": "One-time passcode is invalid.",
            "useExpiredToken": "Use expired token",
            "submit": "Submit",
            "didNotGetPasscode": "Didn’t get your passcode?",
            "exceededOTPMessage": "You have exceeded the number of attempts to enter the one-time passcode. Please try again later.",
            "otpRetryMessage": "You have exceeded the number of attempts to enter the one-time passcode. Please try again later."
        },
        ServiceCenterPageDiversion: {
            "subHeader": "Your session has expired. To continue, click “Exit” below.",
            "Exit": "Exit"
        },
        PasswordPage: {
            "subHeader": "Create a new password",
            "bodyText": " Your new password cannot be:",
            "passwordText1":"a password you used before",
            "passwordText2":"changed more than once a day",
            "passwordText3":"a common word or phrase",
            "password": "New Password",
            "passwordPlaceholder": "Enter new password",
            "confirmPassword": "Confirm New Password",
            "passwordValidation1": "Is between 8 and 50 characters",
            "passwordValidation2": "At least 1 uppercase letter",
            "passwordValidation3": "At least 1 lowercase letter",
            "passwordValidation4": " At least 1 special character",
            "passwordValidation5": "At least 1 number",
            "confirmPasswordPlaceholder": "Confirm new password",
            "validationPasswordMessage": "Passwords do not match.",
            "changePassword": "Change Password",
            "exit": "Exit",
            "passwordValidation6": "Password must be between 8 and 50 characters.",
            "tooltip":"Special character must be at least 1 of the following:  ` ~ ! @ # $ % ^ & * ( ) _ + - = [ ] \ { } | ; ‘ : “ , . / < > ?"
        },
        SuccessPage: {
            "subHeader": "Password Change Successful!",
            "bodyTextPart1": "You can now",
            "loginLinkText": "log in",
            "bodyTextPart2": "with your new password.",
            "exit": "Exit"
        },
        PrivacyStatementModal: {    
            "subHeader": "Privacy Statement",
            "bodyText": "The information requested on this form is required by Covered California to assist with your request, and the information you provide will be used by Covered California solely for that purpose. Legal references authorizing the collection of the personally-identifiable information through this form include Sections 1798.22, 1798.25, 1798.27 and 1798.35 of the California Civil Code and Section 155.260(a) of the Code of Federal Regulations. California Health Benefits Exchange dba Covered California, 1601 Exposition Blvd, Sacramento, CA 95815 (800) 889-3871.",
            "close": "Close"
        },
        PasscodeModal: {
            "subHeader": "Didn’t get your passcode?",
            "bodyText": "Call us to verify your identity and reset your password.",
            "callUs": "(855) 708-4590",
            "close": "Close"
        }
    },
    es: {
        WelcomePage: {
            "subHeader": "Restablecemos tu contraseña.",
            "bodyText": "Necesitarás saber tu nombre de usuario para restablecer tu contraseña",
            "forgotPassword": "¿Olvidaste tu nombre de usuario?",
            "Begin": "Comenzar",
            "Exit": "Salir"
        },
        AuthenticationPage: {
            "header": "Restablece tu contraseña.",
            "subHeader": "Ingresa tu información a continuación.",
            "username": "Nombre de usuario",
            "enterUsername": "Por favor ingresa tu nombre de usuario.",
            "userNamePlaceholder": "Ingresa tu nombre de usuario",
            "forgotUsername": "¿Olvidaste tu nombre de usuario?",
            "dateOfBirth": "Fecha de nacimiento",
            "dateOfBirthPlaceholder": "mm/dd",
            "validationMessage": "Ingresa una fecha de nacimiento válida en el siguiente formato: mm/dd.",
            "privacyStatement": "Declaración de privacidad",
            "submit": "Enviar"
        },
        ServiceCenterPage: {
            "subHeader": "Llámanos para restablecer tu contraseña.",
            "bodyText": "No tienes un método de verificación registrado.",
            "serviceCenter": "Centro de Servicio de Covered California",
            "phoneNumber": "(855) 708-4590",
            "serviceHours": "Horario de atención",
            "extendedHours": "Horario extendido",
            "Exit": "Salir"
        },
        OtpTypePage: {
            "subHeader": "Recibe un código de acceso de un solo uso",
            "bodyText": "Para verificar tu identidad, ¿cómo te gustaría recibir tu código de acceso de un solo uso?",
            "email": "correo electrónico:",
            "phone": "teléfono celular: " ,
            "validationMessage": "Debes realizar una selección para continuar.",
            "Note": "*Se aplica la tarifa estándar de mensaje de texto.",
            "token": " Use expired token",
            "Submit": "Enviar",
        },
        PasscodePage: {
            "subHeader": "Verifica tu identidad",
            "bodyText": "Enviamos un código de acceso de un solo uso a",
            "enterPasscode": "Ingresa el código de acceso de un solo uso",
            "validationMessage": "El código de acceso de un solo uso no es válido.",
            "useExpiredToken": "Usar código vencido",
            "submit": "Enviar",
            "didNotGetPasscode": "¿No recibiste tu código de acceso?",
            "exceededOTPMessage": "Has excedido el número de intentos para ingresar el código de acceso de un solo uso. Por favor, inténtalo de nuevo más tarde.",
            "otpRetryMessage": "Has excedido el número de intentos para ingresar el código de acceso de un solo uso. Por favor, inténtalo de nuevo más tarde."
        },

        ServiceCenterPageDiversion: {
            "subHeader": "Tu sesión ha expirado. Para continuar, haz clic en “Salir” a continuación.",
            "Exit": "Salir"
        },
        PasswordPage: {
            "subHeader": "Crea una nueva contraseña",
            "bodyText": "Tu nueva contraseña no puede:",
            "passwordText1":"ser una contraseña que usaste anteriormente",
            "passwordText2":"cambiarse más de una vez al día",
            "passwordText3":"ser una palabra o frase común",
            "password": "Nueva contraseña",
            "passwordPlaceholder": "Ingresa la nueva contraseña",
            "confirmPassword": "Confirma la nueva contraseña",
            "confirmPasswordPlaceholder": "Confirma la nueva contraseña",
            "validationPasswordMessage": "Tu contraseña no cumple con los requisitos.",
            "passwordValidation1": "Tiene entre 8 y 50 caracteres",
            "passwordValidation2": "Tiene al menos 1 letra mayúscula",
            "passwordValidation3": "Tiene al menos 1 letra minúscula",
            "passwordValidation4": "Tiene al menos 1 carácter especial",
            "passwordValidation5": "Tiene al menos 1 número",
            "changePassword": "Cambiar contraseña",
            "exit": "Salir",
            "passwordValidation6": "La contraseña debe tener entre 8 y 50 caracteres.",
            "tooltip":"El carácter especial debe ser al menos 1 de los siguientes:  ` ~ ! @ # $ % ^ & * ( ) _ + - = [ ] \ { } | ; ‘ : “ , . / < > ?" 
        },
        SuccessPage: {
            "subHeader": "¡La contraseña se cambió exitosamente!",
            "bodyTextPart1": "Ahora puedes",
            "loginLinkText": "iniciar sesión",
            "bodyTextPart2": " con tu nueva contraseña.",
            "exit": "Salir"
        },
        PrivacyStatementModal: {
            "subHeader": "Declaración de privacidad",
            "bodyText": "La información solicitada en este formulario es necesaria para que Covered California te ayude con tu solicitud, y la información que proporciones será utilizada por Covered California únicamente para ese propósito. Las referencias legales que autorizan la recopilación de la información de identificación personal a través de este formulario incluyen las Secciones 1798.22, 1798.25, 1798.27 y 1798.35 del Código Civil de California y la Sección 155.260(a) del Código de Regulaciones Federales. California Health Benefits Exchange dba Covered California, 1601 Exposition Blvd, Sacramento, CA 95815 (800) 889-3871.",
            "close": "Cerrar"
        
        },
        PasscodeModal: {
            "subHeader": "¿No recibiste tu código de acceso?",
            "bodyText": "Llámanos para verificar tu identidad y restablecer tu contraseña.",
            "callUs": "(855) 708-4590",
            "close": "Cerrar"
        }
    }
};


