import React from "react";
import SuccessPage from "../PasswordResetWizard/SuccessPage";

const Es = "es";
const En = "en";

export const translations = {
    en: {
        Button: {
            "submit": "Submit",
            "exit": "Exit",
            "begin": "Begin",
            "returnToChat": "Return to chat",
        },
        WelcomePage: {
            "title": "Let’s recover your username.",
            "body": "You need to know the email address used with your Covered California account.",
            "body2": <>If you do not know your email, call us at <br/><a 
            href="1-855-708-4590"
            style={{ textDecoration: "underline", color :"#2D77A3" }}>
            1-855-708-4590</a>.</>,
        },
        ForgotUsernamePage: {
            "title": "Confirm Your Identity",
            "body": "Enter your information below.",
            "formInputs": {
                "email": {
                    "label": "Email Address",
                    "placeholder": "Enter Email Address",
                    "inputValidation": "Please enter an email address."
                },
                "dob": {
                    "label": "Date of Birth",
                    "placeholder": "mm/dd/yyyy",
                    "invalidValidation": "Please enter a valid date of birth in the following format: mm/dd/yyyy."
                },
            },
            "note": <>If you do not have or remember your email, please call us at <a href="1-855-708-4590" style={{ textDecoration: "underline", color :"#2D77A3" }}>1-855-708-4590</a>.</>,
            "privacyStatement": {
                "text": "Privacy Statement",
                "body": "The information requested on this form is required by Covered California to assist with your request, and the information you provide will be used by Covered California solely for that purpose. Legal references authorizing the collection of the personally-identifiable information through this form include Sections 1798.22, 1798.25, 1798.27 and 1798.35 of the California Civil Code and Section 155.260(a) of the Code of Federal Regulations. California Health Benefits Exchange dba Covered California, 1601 Exposition Blvd, Sacramento, CA 95815 (800) 889-3871.",
                "closeButton": "Close",
            },
        },
        SuccessPage: {
            "title": "We emailed your username.",
            "body": <>If you still need help logging in, reach out to us at <a href="1-855-708-4590" style={{ textDecoration: "underline", color :"#2D77A3" }}>1-855-708-4590</a>.</>,
            "note": <>Do you need to <a 
            href=" "target="_blank"
            rel="noreferrer"
            style={{ textDecoration: "underline", color :"#2D77A3" }}>reset your password</a>? </>
        },

    },
    es: {
        Button: {
            "submit": "Enviar",
            "exit": "Salir",
            "begin": "Comenzar",
            "returnToChat": "Volver al chat",
        },
        WelcomePage: {
            "title": "Recuperemos tu nombre de usuario.",
            "body": "Necesitas saber la dirección de correo electrónico utilizada con tu cuenta de Covered California.",
            "body2": <>Si no tienes o no recuerdas tu correo electrónico, llámanos al <a 
            href="tel:1-855-708-4590" 
            style={{ textDecoration: "underline", color :"#2D77A3" }}>
            1-855-708-4590</a>.</>,
        },
        ForgotUsernamePage: {
            "title": "Confirma tu identidad",
            "body": "Ingresa tu información a continuación.",
            "formInputs": {
                "email": {
                    "label": "Correo electrónico",
                    "placeholder": "Ingresa el correo electrónico",
                    "inputValidation": "Por favor ingresa un correo electrónico."
                },
                "dob": {
                    "label": "Fecha de nacimiento",
                    "placeholder": "mm/dd/aaaa",
                    "invalidValidation": "Por favor ingresa una fecha de nacimiento válida."
                },
            },
            "note": <>Si no tienes o no recuerdas tu correo electrónico, llámanos al  <a href="tel:1-855-708-4590"  style={{ textDecoration: "underline", color :"#2D77A3" }}>1-855-708-4590</a>.</>,
            "privacyStatement": {
                "text": "Declaración de privacidad",
                "body": "Covered California requiere la información solicitada en este formulario para ayudar con tu solicitud, y la información que proporciones será utilizada únicamente para ese fin. Las referencias legales que autorizan la recopilación de información de identificación personal a través de este formulario incluyen las Secciones 1798.22, 1798.25, 1798.27 y 1798.35 del Código Civil de California y la Sección 155.260(a) del Código de Reglamentos Federales. California Health Benefits Exchange dba Covered California, 1601 Exposition Blvd, Sacramento, CA 95815 (800) 889-3871.",
                "closeButton": "Cerrar"
            },
        },
        SuccessPage: {
            "title": "Te enviamos tu nombre de usuario.",
            "body": <>Si no lo recibes o no puedes iniciar sesión, comunícate con el Centro de Servicio de Covered California al <a href="tel:1-855-708-4590" style={{ textDecoration: "underline", color :"#2D77A3" }}>1-855-708-4590</a>.</>,
            "note": <>¿Necesitas <a 
            href=" "target="_blank"
            rel="noreferrer"
            style={{ textDecoration: "underline", color :"#2D77A3" }}>restablecer tu contraseña</a>? </>
        },

    }    

};