import { useContext, useState } from "react";
import { ChatbotContext } from "../Contexts/ChatbotContext";
import { ForgotUsernameContext} from "../Contexts/ForgotUsernameContext";
import { useLanguage } from "../UiKit/Wizard/langugeContext";
import useWebChat from "./useWebChat";


function useForgotUsername() {
    const {
        isForgotUsernameMenuOpen,
        setIsForgotUsernameMenuOpen,
        currentPage,
        setCurrentPage,
        closeModal, 
        setCloseModal,
        helpModal,
        setHelpModal,
        offBusinessHoursHelpModal,
        setOffBusinessHoursHelpModal,
       
       
    } = useContext(ForgotUsernameContext);

    const { currentLanguage } = useLanguage();
    const { setIsForgotUsernameWizardOpen } = useContext(ChatbotContext);
    const { sendActivity } = useWebChat();

    const resetForgotUsernameState = () => {
     // console.log("Resetting ForgotUsername State");
    }
    
    const next = () => {
      //  console.log("Current Page", currentPage);
        setCurrentPage(currentPage + 1);
    };
    
    const previous = () => {
       // console.log("Current Page", currentPage);
        if (currentPage === 0) return;
        if (currentPage > 0) {
           // console.log("Current Page inside", currentPage,  (currentPage - 1));
            setCurrentPage(currentPage - 1);
        }
    };
    
    const closeForgotUsername = () => {
     // console.log("Closing forgot username wizard");
      sendActivity(currentLanguage === "en" ? { text: "Forgot Username", type: "message" } : { text: "Olvidé mi nombre de usuario", type: "message" });
    };

    const openFixedCloseModal = () => {
        setCloseModal({isOpen: true, type: "fixed"});
    };
    const openRelativeCloseModal = () => {
       // console.log("Opening relative close modal");
        setCloseModal({isOpen: true, type: "relative"});
    };
    const closeCloseModal = () => {
     // console.log("Closing Close Modal");
        setCloseModal({isOpen: false, type: null});
    }
    
    const openFixedHelpModal = () => {
      setHelpModal({isOpen: true, type: "fixed"});
    };
    const openRelativeHelpModal = () => {
      setHelpModal({isOpen: true, type: "relative"});
    };
    
    const closePhoneSupportModal = () => {
      setHelpModal({isOpen: false, type: null});
    }
    
    const openFixedOffHoursHelpModal = () => {
      setOffBusinessHoursHelpModal({isOpen: true, type: "fixed"});
    };
    const openRelativeOffHoursHelpModal = () => {
      setOffBusinessHoursHelpModal({isOpen: true, type: "relative"});
    };
    
    const closePhoneSupportOffHoursModal = () => {
      setOffBusinessHoursHelpModal({isOpen: false, type: null});
    }

    const toggleForgotUsernameMenu = () => {
        console.log("Im here in reset toggle menu");
        setIsForgotUsernameMenuOpen(!isForgotUsernameMenuOpen);
      } 
    
    return {
        isForgotUsernameMenuOpen,
        setIsForgotUsernameMenuOpen,
        next,
        previous,
        closeForgotUsername,
        currentPage,
        setCurrentPage,
        closeModal,
        closeCloseModal,
        openFixedCloseModal,
        openRelativeCloseModal,
        helpModal,
        openFixedHelpModal,
        openRelativeHelpModal,
        closePhoneSupportModal,
        openFixedOffHoursHelpModal,
        openRelativeOffHoursHelpModal,
        closePhoneSupportOffHoursModal,
        toggleForgotUsernameMenu,
        offBusinessHoursHelpModal,
        resetForgotUsernameState, 
    };
    }

export default useForgotUsername;