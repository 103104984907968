import React, { useState, useEffect, useRef } from "react";
import PageLayout from "../../UiKit/Wizard/Page/PageLayout";
import NavigationButton from "../../UiKit/Wizard/NavigationButton/NavigationButton";
import usePasswordReset from "../../Hooks/usePasswordReset";
import SubHeader from "../../UiKit/Wizard/SubHeader/SubHeader";
import Body from "../../UiKit/Wizard/Body/Body";
import { useLanguage } from "../../UiKit/Wizard/langugeContext";
import { translations } from "../../Components/PasswordResetWizard/translations";
import useChatbot from "../../Hooks/useChatbot";
import useWebChat from "../../Hooks/useWebchat/useWebChat";

function WelcomePage({ onNext }) {
    const { currentLanguage } = useLanguage();
    const { closePasswordReset } = usePasswordReset();
    const { closePasswordResetWizard } = useChatbot();
    
    const welcomePageTranslations = translations[currentLanguage] 
        ? translations[currentLanguage]["WelcomePage"]
        : translations["en"]["WelcomePage"];

    return (
        <PageLayout>
            <PageLayout.Content>
                {/* Pass currentLanguage explicitly to Content */}
                <Content welcomePageTranslations={welcomePageTranslations} currentLanguage={currentLanguage} />
            </PageLayout.Content>
            <PageLayout.Navigation>
                <NavigationButton onClick={() => onNext()} type="primary">
                    {welcomePageTranslations.Begin}
                </NavigationButton>
                <NavigationButton onClick={() => closePasswordReset()} type="secondary">
                    {welcomePageTranslations.Exit}
                </NavigationButton>
            </PageLayout.Navigation>
        </PageLayout>
    );
}

function Content({ welcomePageTranslations, currentLanguage }) {
    const { sendMessage } = useWebChat();
    const { closePasswordReset } = usePasswordReset();
    const [closeTriggered, setCloseTriggered] = useState(0);
    const timeoutRef = useRef(null);

    // console.log("currentLanguage:", currentLanguage);
    const message = currentLanguage === "en" ? "Forgot my username" : "Olvidé mi nombre de usuario";

    const handlePasswordReset = () => {
        closePasswordReset();
        console.log("closePasswordReset");
        setTimeout(() => {
            //console.log(`Sending message: ${message}`);
            sendMessage(message);
        }, 1000);
    };
    return (
        <div
            style={{
                fontSize: "21px",
                lineHeight: "29.4px",
                fontWeight: "normal",
                margin: "0",
            }}
        >
            <SubHeader
                style={{ display: "flex", marginBottom: "8px", marginTop: "0px" }}
                subHeader={welcomePageTranslations.subHeader}
            />
            <Body
                bodyText={
                    <>
                        <div style={{ marginTop: "10px" }}>
                            {welcomePageTranslations.bodyText}{" "}
                            <a
                                style={{
                                    color: "var(--CalHEERS-Main-Blue, #2D77A3)",
                                    textDecoration: "underline",
                                    cursor: "pointer",
                                }}
                                rel="noopener noreferrer"
                                onClick={handlePasswordReset}
                            >
                                {welcomePageTranslations.forgotPassword}
                            </a>
                        </div>
                    </>
                }
            />
        </div>
    );
}

export default WelcomePage;
