import React from 'react';
import Modal from '../../UiKit/Modal/Modal';
import NavigationButton, { NavigationButtonGroup } from '../../UiKit/Wizard/NavigationButton/NavigationButton';
import usePasswordReset from '../../Hooks/usePasswordReset';
import { translations } from "../../Components/PasswordResetWizard/translations";
import { useLanguage } from "../../UiKit/Wizard/langugeContext";

function DidntGetPasscodeModal({ type, insideWizard, handleClose }) {
    const { closeCloseModal } = usePasswordReset();
    const { currentLanguage } = useLanguage();
    const PasscodeTranslations = translations[currentLanguage]["PasscodeModal"];

    const handleCallClick = () => {
        window.open('tel:880-708-4590', '_blank');
    };

    return (
        <Modal type={type}>
            <Modal.Dialog> {/* Apply class here */}
                <Modal.Header>
                    <Modal.Close onClick={handleClose} />
                </Modal.Header>
                <Modal.Body>
                    <Modal.Title style={{fontSize: '18px'}}>{PasscodeTranslations.subHeader}</Modal.Title>
                    <Modal.Content>
                       {PasscodeTranslations.bodyText}
                    </Modal.Content>
                    <NavigationButtonGroup>
                        <NavigationButton
                            style={{ marginBottom: "8px" }}
                            type={"primary"}
                            onClick={handleCallClick}
                        >
                            (880) 708-4590
                        </NavigationButton>
                        <NavigationButton 
                            style={{ marginBottom: "8px" }} 
                            type={"secondary"} 
                            onClick={handleClose}>
                            {PasscodeTranslations.close}
                        </NavigationButton>
                    </NavigationButtonGroup>
                </Modal.Body>
            </Modal.Dialog>
        </Modal>
    );
}

export default DidntGetPasscodeModal;
