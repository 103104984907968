import React, { useState, useEffect, useRef, useContext } from "react";
import PageLayout from "../../UiKit/Wizard/Page/PageLayout";
import NavigationButton from "../../UiKit/Wizard/NavigationButton/NavigationButton";
import useForgotUsername from "../../Hooks/useForgotUsername";
import SubHeader from "../../UiKit/Wizard/SubHeader/SubHeader";
import Form from "../../UiKit/Form/Form";
import Input from "../../UiKit/Wizard/Input/Input";
import WizardLayout from "../../UiKit/Wizard/WizardLayout/WizardLayout";
import Body from "../../UiKit/Wizard/Body/Body";
import sendEventToDataLayer from "../../___archive/datalayer-event-logger-for-transactions/sendEventToDataLayer";
import InputMask from 'react-input-mask';
import ReCAPTCHA from "react-google-recaptcha";
import InputValidationMessage from "../../UiKit/InputValidationMessage/InputValidationMessage";
import { ForgotUsernameContext } from "../../Contexts/ForgotUsernameContext";
import  Alert  from "../../UiKit/Wizard/Alert/Alert";
import useChatbot from "../../Hooks/useChatbot";
import PrivacyStatementModal from "./PrivacyStatementModal";
import { translations } from "../ForgotUsername/translations";
import { useLanguage } from "../../UiKit/Wizard/langugeContext";
import "./ForgotUsername.css";
import styles from '../../UiKit/Wizard/WizardLayout/WizardLayout.module.css';

const CAPTCHA_SITE_KEY = process.env.NODE_ENV === 'development' ? "6Lea14UkAAAAADFKcSV8372FNO5CoTEy4RLktKjW" : "6Ld32mQkAAAAAJDLX9kgVM56Zl8-IgOj3Xm9GawQ";

function ResetPasswordPage(props) {
  const { next, closeModal, openRelativeCloseModal } = useForgotUsername();
  const { dob, setDob,exceededAttemptMessage,userNotFound, serviceNotAvailable, email, setEmail } = useContext(ForgotUsernameContext);
  const [captchaToken, setCaptchaToken] = useState("");
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [isCaptchaHidden, setIsCaptchaHidden] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isUsernameValidationVisible, setIsUsernameValidationVisible] = useState(false);
  const [isDobValidationVisible, setIsDobValidationVisible] = useState(false);
  const [isPrivacyStatementExpanded, setIsPrivacyStatementExpanded] = useState(false);
  const [isEmailValidationVisible, setIsEmailValidationVisible] = useState(false);
  const captchaRef = useRef();
  const alertRef = useRef(); 
  const {closePasswordResetWizard} = useChatbot();
  const [showPrivacyModal, setShowPrivacyModal] = useState(false);
  const { onNext, postActivity, sendActivity, onPrevious} = props;
  const [alertReady, setAlertReady] = useState(false);
  const { currentLanguage } = useLanguage();
  const forgotUserNametranslations = translations[currentLanguage]["ForgotUsernamePage"];
  const buttontranslations = translations[currentLanguage]["Button"];


  const onSubmit = async () => {
    let hasError = false;

    if (!isEmailValid(email)) {
      setIsEmailValidationVisible(true);
      hasError = true;
    } else {
      setIsEmailValidationVisible(false);
    }

    if (!isDobValid(dob)) {
      setIsDobValidationVisible(true);
      hasError = true;
    } else {
      setIsDobValidationVisible(false);
    }

    if (hasError) {
      return;
    }

    const token = await captchaRef.current.executeAsync();
    //const token = '';
    sendActivity({
      type: 'message',
      value: {
        email: email,
        dob: dob,
        captchaToken: token,
        type: 'forgotUsernameCardClick',
        intentName: 'forgotUsername'
      }
    });
    setIsCaptchaHidden(false);
    setIsSubmitted(true);
  };

  const onForgotUsername = () => {
    sendEventToDataLayer({
      event: "chatbot",
      eventDetails: {
        category: "CiCi",
        action: "Password Reset Flow",
        label: "'Forgot Username' Link Click"
      }
    });
    postActivity({
      type: 'message',
      value: {
        intentName: 'forgotUsername'
      }
    });
    setIsSubmitted(true);
  };

  const onCaptchaChange = (newCaptcha) => {
    setCaptchaToken(newCaptcha);
    updateSubmitButtonState(email, dob, newCaptcha);
  };

  const isEmailValid = (newEmail) => {
    return String(newEmail)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  };
  

  const isDobValid = (newDob) => {
    if(dob === ""){
      return false;
    }
    if(isNaN(new Date(newDob))){
      return false;
    }
    return true;
  }

  const areInputsValid = (email, dob) => {
    if (!isEmailValid(email)) {
      return false;
    }
    if (!isDobValid(dob)) {
      return false;
    }
    return true;
  };

  const updateSubmitButtonState = (email, dob) => {
    
    if(areInputsValid(email, dob)){
     
      setIsSubmitDisabled(false);
    }else{
    
      setIsSubmitDisabled(true);
    }
  }

  const onEmailUpdate = (event) => {
    const newEmail = event.target.value;
    setEmail(newEmail);
    if (isEmailValidationVisible) {
      setIsEmailValidationVisible(false);
    }
    updateSubmitButtonState(newEmail, dob);
  }

  const onDobUpdate = (event) => {
    const newDob = event.target.value;
    setDob(newDob);


    if (newDob.length === 10 && !newDob.includes('_')) { 
        if (!isDobValid(newDob)) {
            setIsDobValidationVisible(true);
        } else {
            setIsDobValidationVisible(false);
        }
    } else {
        setIsDobValidationVisible(false);
    }

    updateSubmitButtonState(email, newDob, captchaToken);
};



  useEffect(() => {
    localStorage.setItem("conversationMayContainPii", true);
  }, []);


  const linkStyle = {
    color: 'var(--CalHEERS-Main-Blue, #2D77A3)',
    fontFamily: 'Open Sans',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '20px',
    textDecorationLine: 'underline'
};

useEffect(() => {
  // Enable scrolling only if there is an alert message
  if (props.exceededAttemptMessage || props.userNotFound || props.serviceNotAvailable) {
    document.body.classList.add('scrollable'); // Allow scrolling
  } else {
    document.body.classList.remove('scrollable'); // Prevent scrolling
  }
}, [props.exceededAttemptMessage, props.userNotFound, props.serviceNotAvailable]);



useEffect(() => {
  const focusAlert = () => {
    if (alertRef.current) {
      alertRef.current.focus();
      alertRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
    } else {
      setTimeout(focusAlert, 300); 
    }
  };

  focusAlert();
}, [props.userNotFound]);

return (
  <>
    {/* CSS for controlling scrollability */}
    <style>
      {`
        body {
          overflow: hidden;
        }
        body.scrollable {
          overflow: auto;
        }
      `}
    </style>
    <WizardLayout.Content>
      <PageLayout>
        <PageLayout.Header style={{ marginBottom: "8px" }}>
          <SubHeader subHeader={forgotUserNametranslations.title} />
        </PageLayout.Header>
        <PageLayout.Content>
          <Body
            bodyText={
              <>
                <div style={{ marginTop: '0px' }}>
                  {forgotUserNametranslations.body}
                </div>
              </>
            }
          />
          <Form>
            <Form.FormGroup>
              <Form.Label>{forgotUserNametranslations.formInputs.email.label}</Form.Label>
              <Form.Control>
                <Input
                  style={isEmailValidationVisible ? { border: '1px solid red' } : {} }
                  onChange={onEmailUpdate}
                  value={email}
                  placeholder={forgotUserNametranslations.formInputs.email.placeholder}
                />
                {isEmailValidationVisible && <InputValidationMessage>{forgotUserNametranslations.formInputs.email.inputValidation}</InputValidationMessage>}
              </Form.Control>
            </Form.FormGroup>
            <Form.FormGroup>
              <Form.Label>{forgotUserNametranslations.formInputs.dob.label}</Form.Label>
              <Form.Control>
                <InputMask
                  style={{ 
                    ...(isDobValidationVisible ? { border: '1px solid red' } : { border: '1px solid #BABABC' }), 
                    padding: '10.5px 12px' 
                  }}
                  mask="99/99/9999"
                  maskChar="_"
                  className="form-control"
                  placeholder={forgotUserNametranslations.formInputs.dob.placeholder}
                  onChange={onDobUpdate}
                  value={dob}
                />
              </Form.Control>
              {isDobValidationVisible && <InputValidationMessage>{forgotUserNametranslations.formInputs.dob.invalidValidation}</InputValidationMessage>}
            </Form.FormGroup>
          </Form>

          <div className="note" style={{ marginTop: '32px' }}>
            <p>{forgotUserNametranslations.note}</p>
          </div>

          {/* Alerts section to allow scrolling when present */}
          <div style={{ marginTop: props.exceededAttemptMessage || props.userNotFound || props.serviceNotAvailable ? '32px' : '0px' }}>
            {props.exceededAttemptMessage && (
              <div
                ref={(ref) => {
                  if (ref) {
                    alertRef.current = ref;
                    ref.focus();
                    ref.scrollIntoView({ behavior: "smooth", block: "center" });
                  }
                }}
                tabIndex={-1}
              >
                <Alert
                  iconType="red"
                  frameIcon="red"
                  message={props.exceededAttemptMessage || props.userNotFound || props.serviceNotAvailable}
                />
              </div>
            )}

            {props.userNotFound && (
              <div ref={alertRef} tabIndex={-1}>
                <Alert
                  iconType="red"
                  frameIcon="red"
                  message={
                    currentLanguage === 'es' 
                      ? "No pudimos encontrar una cuenta con esta información." 
                      : props.userNotFound
                  }
                />
              </div>
            )}

            {props.serviceNotAvailable && (
              <div ref={alertRef} tabIndex={-1}>
                <Alert
                  iconType="red"
                  frameIcon="red"
                  message={props.serviceNotAvailable}
                />
              </div>
            )}
          </div>

          <div className="d-none">
            <ReCAPTCHA
              sitekey={CAPTCHA_SITE_KEY}
              ref={captchaRef}
              size="invisible"
            />
          </div>
          <div className="my-3" style={{ ...linkStyle, paddingBottom: '20px' }}>
            <a  style ={{color:'#2D77A3'}} href="#!" onClick={() => setShowPrivacyModal(true)}>{forgotUserNametranslations.privacyStatement.text}</a>
          </div>
        </PageLayout.Content>
      </PageLayout>
    </WizardLayout.Content>
    <div style={{ position: "relative" }}>
        <div className={`${styles.overlaybottom}`}style={{height: '62px'}}></div>
    </div>
    <WizardLayout.Navigation>
      <NavigationButton onClick={onSubmit} type={"primary"} disabled={isSubmitDisabled || isSubmitted}>
        {buttontranslations.submit}
      </NavigationButton>
    </WizardLayout.Navigation>
    {showPrivacyModal && (
      <PrivacyStatementModal type="fixed" insideWizard={true} handleClose={() => setShowPrivacyModal(false)} />
    )}
  </>
);
}

export default ResetPasswordPage;