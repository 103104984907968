import React from 'react';
import Modal from '../../UiKit/Modal/Modal';
import NavigationButton, { NavigationButtonGroup } from '../../UiKit/Wizard/NavigationButton/NavigationButton';
import Body from "../../UiKit/Wizard/Body/Body";
import { translations } from "../../Components/PasswordResetWizard/translations";
import { useLanguage } from "../../UiKit/Wizard/langugeContext";

function PrivacyStatementModal({ type, insideWizard, handleClose }) {
    const { currentLanguage } = useLanguage();
    const privacyStatementTranslations = translations[currentLanguage]["PrivacyStatementModal"];
    return (
        <Modal type={type}>
            <Modal.Dialog style={{ height: "417px"}}>
                <Modal.Header>
                    {/* <Modal.Title style={{ fontSize: "18px", lineHeight: "24px"}}>Privacy Statement</Modal.Title> */}
                    <Modal.Close onClick={handleClose} />
                </Modal.Header>
                <Modal.Body>
                <Modal.Title style={{ fontSize: "18px", lineHeight: "24px"}}>{privacyStatementTranslations.subHeader}</Modal.Title>
                <Modal.Content>
                    <Body
                        bodyText={
                            <>
                                <div style={{ marginTop: '0px', fontSize: "12px", lineHeight: "16px" }}>
                                    {privacyStatementTranslations.bodyText}
                                </div>
                            </>
                        }
                    />
                    </Modal.Content>
                    <NavigationButtonGroup style={{ paddingTop: "24px", paddingBottom: "24px", paddingLeft:"16px", paddingRight: "16px"}}>
                        <NavigationButton style={{ marginBottom: "8px"}} type={"primary"} onClick={handleClose}>{privacyStatementTranslations.close}</NavigationButton>
                    </NavigationButtonGroup>
                </Modal.Body>
            </Modal.Dialog>
        </Modal>
    );
}

export default PrivacyStatementModal;
