import React, { useContext, useEffect, useState } from "react";
import WizardLayout from "../../UiKit/Wizard/WizardLayout/WizardLayout";
import usePasswordReset from "../../Hooks/usePasswordReset";
import ChatbotLayout from "../../UiKit/Chatbot/ChatbotLayout/ChatbotLayout";
import Header from "../../UiKit/Wizard/Header/Header";
import WelcomePage from "./WelcomePage";
import AuthenticationPage from "./AuthenticationPage";
import OtpTypePage from "../PasswordResetWizard/OtpTypePage";
import PasscodePage from "./PasscodePage";
import PasswordPage from "./PasswordPage";
import SuccessPage from "./SuccessPage";
import ServiceCenterPage from "./ServiceCenterPage";
import ServiceCenterHoursPage from "./ServiceCenterHoursPage";
import PasswordResetHeader from "./PasswordResetHeader";
import styles from "../../UiKit/Wizard/Header/Header.module.css";
import useChatbot from "../../Hooks/useChatbot";
import PasswordResetContextProvider from "../../Contexts/PasswordResetContext";
import CloseModal from "./CloseModal";
import PasswordResetMenu from "./PasswordResetMenu";
import DidntGetPasscodeModal from "./DidntGetPasscodeModal";
import WebchatMenu from "../Webchat/WebchatMenu/WebchatMenu";
import { useLanguage } from "../../UiKit/Wizard/langugeContext";


function PasswordResetWizard({
  postActivity,
  sendActivity,
  passwordResetEvent,
}) {
  return (
    <PasswordResetContextProvider>
      <PasswordResetWizardHelper
        postActivity={postActivity}
        sendActivity={sendActivity}
        passwordResetEvent={passwordResetEvent}
      />
    </PasswordResetContextProvider>
  );
}

export const PAGES = {
  WELCOME: 0,
  AUTHENTICATION_PAGE: 1,
  OTP_TYPE_PAGE: 2,
  PASSCODE_PAGE: 3,
  PASSWORD_PAGE: 4,
  SUCCESS_PAGE: 5,
  SERVICE_CENTER: 6,
  SERVICE_CENTER_HOURS: 7,
};

function PasswordResetWizardHelper({ postActivity, sendActivity }) {
  const {
    close,
    currentPage,
    setCurrentPage,
    next,
    previous,
    closeModal,
    openRelativeCloseModal,
    isDidNotGetPasscodeModalOpen,
    setIsDidNotGetPasscodeModalOpen
  } = usePasswordReset();
  const { passwordResetEvent, openPasswordResetWizard, closePasswordResetWizard, closeForgotUsernameWizard } = useChatbot();
  const [isUserNotFound, setIsUserNotFound] = useState(false);
  const [userNotFoundMessage, setUserNotFoundMessage] = useState("");
  const [otpServiceUnavailable, setotpServiceUnavailable] = useState(false);
  const [otpServiceUnavailableMessage, setotpServiceUnavailableMessage] =
    useState("");
  useEffect(() => {
    if (passwordResetEvent.name === "openWelcomePage") {
      setCurrentPage(PAGES.WELCOME);
    }
  }, [passwordResetEvent, setCurrentPage]);
  const language = useLanguage();

  useEffect(() => {
    if (passwordResetEvent.data && passwordResetEvent.flag === "userNotFound") {
      setIsUserNotFound(true);
      setUserNotFoundMessage(passwordResetEvent.data.userNotFound);
      return;
    }
    // if (passwordResetEvent.data && passwordResetEvent.flag === 'otpServiceUnavailable') {
    //     console.log("OTP Service Unavailable flag setting to true");
    //     setotpServiceUnavailable(true);
    //     setotpServiceUnavailableMessage(passwordResetEvent.data.otpServiceUnavailable);
    //     return;
    // }

    if (passwordResetEvent.name === "openWelcomePage") return;
    // if (passwordResetEvent.name === 'openAuthenticationPage') return;
    // setotpServiceUnavailable(false);
    // setotpServiceUnavailableMessage("");
    setIsUserNotFound(false);
    setUserNotFoundMessage("");
  }, [passwordResetEvent]);

  useEffect(() => {
    if (passwordResetEvent.name === "cancel") {

      sendActivity({type: "message", value: { intentName: "cancelTransaction" }});
    }
    if (passwordResetEvent.name === 'cancelConfirmed'){
      closePasswordResetWizard();
      closeForgotUsernameWizard();
    }
  }, [passwordResetEvent]);

  const getContent = () => {

    // console.log("passwordResetEvent.name", passwordResetEvent.name);
    // console.log("isUserNotFound", isUserNotFound);
    if (passwordResetEvent.name === "openWelcomePage" && !isUserNotFound) {
      setCurrentPage(PAGES.WELCOME);
    }
    if (
      passwordResetEvent.name === "openAuthenticationPage" ||
      (passwordResetEvent.name === "openWelcomePage" && isUserNotFound)
      //  && !otpServiceUnavailable
    ) {
      setCurrentPage(PAGES.AUTHENTICATION_PAGE);
    }
    if (passwordResetEvent.name === "openOtpSelectionPage") {
      setCurrentPage(PAGES.OTP_TYPE_PAGE);
    }
    if (passwordResetEvent.name === "openPasscodePage") {
      setCurrentPage(PAGES.PASSCODE_PAGE);
    }
    if (passwordResetEvent.name === "openPasswordPage") {
      setCurrentPage(PAGES.PASSWORD_PAGE);
    }
    if (passwordResetEvent.name === "openSuccessPage") {
      setCurrentPage(PAGES.SUCCESS_PAGE);
    }
    if (passwordResetEvent.name === "openServiceCenterPage") {
      setCurrentPage(PAGES.SERVICE_CENTER);
    }
    if (passwordResetEvent.name === "openServiceCenterHoursPage") {
      setCurrentPage(PAGES.SERVICE_CENTER_HOURS);
    }

    return (
      <>
        {currentPage === 0 && (
          <WelcomePage
            postActivity={postActivity}
            sendActivity={sendActivity}
            onNext={() =>
              openPasswordResetWizard({
                name: "openAuthenticationPage",
                data: {},
              })
            }
            onClose={close}
          />
        )}
        {currentPage === 1 && (
          <AuthenticationPage
            postActivity={postActivity}
            sendActivity={sendActivity}
            onNext={next}
            onPrevious={() =>
              openPasswordResetWizard({ name: "openWelcomePage", data: {} })
            }
            passwordResetEvent={passwordResetEvent}
            exceededAttemptMessage={
              passwordResetEvent.data
                ? passwordResetEvent.data.exceededAttemptMessage
                : ""
            }
            userNotFound={isUserNotFound ? userNotFoundMessage : ""}
            serviceNotAvailable={
              passwordResetEvent.data
                ? passwordResetEvent.data.serviceNotAvailable
                : ""
            }
          />
        )}
        {currentPage === 2 && (
          <OtpTypePage
            sendActivity={sendActivity}
            onNext={next}
            onPrevious={() =>
              openPasswordResetWizard({
                name: "openAuthenticationPage",
                data: {},
              })
            }
            passwordResetEvent={passwordResetEvent}
          />
        )}
        {currentPage === 3 && (
          <PasscodePage
            sendActivity={sendActivity}
            // setCurrentPage={setCurrentPage}
            passwordResetEvent={passwordResetEvent}
            onPrevious={() =>
              openPasswordResetWizard({
                name: "openOtpSelectionPage",
                data: {},
              })
            }
            exceededOTPMessage={
              passwordResetEvent.data
                ? passwordResetEvent.data.exceededOTPMessage
                : ""
            }
            OTPRetry={
              passwordResetEvent.data ? passwordResetEvent.data.OTPRetry : ""
            }
            OTPSessionExpired={
              passwordResetEvent.data
                ? passwordResetEvent.data.OTPSessionExpired
                : ""
            }
            OTPServiceUnavailable={
              passwordResetEvent.data
                ? passwordResetEvent.data.OTPServiceUnavailable
                : ""
            }
          />
        )}
        {currentPage === 4 && (
          <PasswordPage
            postActivity={postActivity}
            sendActivity={sendActivity}
            onNext={next}
            onPrevious={() =>
              openPasswordResetWizard({ name: "openPasscodePage", data: {} })
            }
            passwordResetEvent={passwordResetEvent}
            passwordInvalid={
              passwordResetEvent.data
                ? passwordResetEvent.data.passwordInvalid
                : ""
            }
          />
        )}
        {currentPage === 5 && (
          <SuccessPage
            postActivity={postActivity}
            sendActivity={sendActivity}
            passwordResetEvent={passwordResetEvent}
            onNext={close}
          />
        )}
        {currentPage === 6 && (
          <ServiceCenterPage
            postActivity={postActivity}
            sendActivity={sendActivity}
            onNext={close}
            //setCurrentPage={PAGES.WELCOME}
          />
        )}
        {currentPage === 7 && (
          <ServiceCenterHoursPage
            postActivity={postActivity}
            sendActivity={sendActivity}
            onNext={close}
          />
        )}
      </>
    );
  };

  const handleClick = () => {

    openRelativeCloseModal();
  };

  return (
    <ChatbotLayout>
      <WebchatMenu />
      {closeModal.isOpen && <CloseModal type={closeModal.type} />}
      {isDidNotGetPasscodeModalOpen && <DidntGetPasscodeModal handleClose={()=>setIsDidNotGetPasscodeModalOpen(false)}/>}
      <ChatbotLayout.Header>
        <PasswordResetHeader />
      </ChatbotLayout.Header>
      <ChatbotLayout.Wizard>
        <WizardLayout>
          <WizardLayout.Header>
            <Header>
              <div className={styles.title} onClick={handleClick}>
                {language.currentLanguage === "es" ? "Volver al chat" : "Return to chat"}
                {/* Return to chat */}
              </div>
            </Header>
          </WizardLayout.Header>
          {getContent()}
        </WizardLayout>
      </ChatbotLayout.Wizard>
    </ChatbotLayout>
  );
}

export default PasswordResetWizard;
