import React from "react";
import PageLayout from "../../UiKit/Wizard/Page/PageLayout";
import NavigationButton from "../../UiKit/Wizard/NavigationButton/NavigationButton";
import SubHeader from "../../UiKit/Wizard/SubHeader/SubHeader";
import Body from "../../UiKit/Wizard/Body/Body";
import usePasswordReset from "../../Hooks/usePasswordReset";
import useChatbot from "../../Hooks/useChatbot";
import Alert from "../../UiKit/Wizard/Alert/Alert";
import { PAGES } from "./PasswordResetWizard";
import { translations } from "../../Components/PasswordResetWizard/translations";
import { useLanguage } from "../../UiKit/Wizard/langugeContext";

function ServiceCenterPage(props) {
    const { closePasswordReset, setCurrentPage, resetPasswordResetState } = usePasswordReset();
    const {closePasswordResetWizard, openWelcomePage} = useChatbot();
    const { currentLanguage } = useLanguage();
    const serviceCenterPageTranslations = translations[currentLanguage]["ServiceCenterPageDiversion"];

    const handleStartOver = () => {
         //resetPasswordResetState();
        openWelcomePage();
        //setCurrentPage(PAGES.WELCOME);  // Set the current page to WELCOME
    };


    return (
        <PageLayout>
            <PageLayout.Content>
                 <Alert
                        iconType='red'
                        frameIcon='red'
                        message={serviceCenterPageTranslations.subHeader}
                    />
            </PageLayout.Content>
            <PageLayout.Navigation>
                <NavigationButton 
                // onClick={() => closePasswordReset()} 
                 onClick={() => closePasswordResetWizard()}
               // onClick={handleStartOver}  // Navigate to WelcomePage
                type="primary" style={{ marginBottom: "36px" }}>
               {serviceCenterPageTranslations.Exit}
                </NavigationButton>
            </PageLayout.Navigation>
        </PageLayout>
    );
}

export default ServiceCenterPage;
