import React, { useState, useEffect, useRef, useContext } from "react";
import PageLayout from "../../UiKit/Wizard/Page/PageLayout";
import NavigationButton from "../../UiKit/Wizard/NavigationButton/NavigationButton";
import usePasswordReset from "../../Hooks/usePasswordReset";
import SubHeader from "../../UiKit/Wizard/SubHeader/SubHeader";
import Form from "../../UiKit/Form/Form";
import Input from "../../UiKit/Wizard/Input/Input";
import WizardLayout from "../../UiKit/Wizard/WizardLayout/WizardLayout";
import Body from "../../UiKit/Wizard/Body/Body";
import sendEventToDataLayer from "../../___archive/datalayer-event-logger-for-transactions/sendEventToDataLayer";
import InputMask from 'react-input-mask';
import ReCAPTCHA from "react-google-recaptcha";
import InputValidationMessage from "../../UiKit/InputValidationMessage/InputValidationMessage";
import { PasswordResetContext } from "../../Contexts/PasswordResetContext";
import  Alert  from "../../UiKit/Wizard/Alert/Alert";
import useChatbot from "../../Hooks/useChatbot";
import PrivacyStatementModal from "./PrivacyStatementModal";
import './PasswordReset.css';
import { translations } from "../../Components/PasswordResetWizard/translations";
import { useLanguage } from "../../UiKit/Wizard/langugeContext";
import useWebChat from "../../Hooks/useWebchat/useWebChat";


const CAPTCHA_SITE_KEY = process.env.NODE_ENV === 'development' ? "6Lea14UkAAAAADFKcSV8372FNO5CoTEy4RLktKjW" : "6Ld32mQkAAAAAJDLX9kgVM56Zl8-IgOj3Xm9GawQ";

function ResetPasswordPage(props) {
  const { next, closeModal, openRelativeCloseModal } = usePasswordReset();
  const { username, setUsername, dob, setDob,exceededAttemptMessage,userNotFound, serviceNotAvailable } = useContext(PasswordResetContext);
  // const [username, setUsername] = useState("");
  // const [dob, setDob] = useState("");
  const [captchaToken, setCaptchaToken] = useState("");
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [isCaptchaHidden, setIsCaptchaHidden] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isUsernameValidationVisible, setIsUsernameValidationVisible] = useState(false);
  const [isDobValidationVisible, setIsDobValidationVisible] = useState(false);
  const [isPrivacyStatementExpanded, setIsPrivacyStatementExpanded] = useState(false);
  const captchaRef = useRef();
  const alertRef = useRef(); 
  const {closePasswordResetWizard} = useChatbot();
  const [showPrivacyModal, setShowPrivacyModal] = useState(false);
  const { onNext, postActivity, sendActivity, onPrevious} = props;
  const [alertReady, setAlertReady] = useState(false); 
  const { currentLanguage } = useLanguage();
  const AuthenticationPageTranslations = translations[currentLanguage] 
  ? translations[currentLanguage]["AuthenticationPage"]
  : translations["en"]["AuthenticationPage"];
  const { sendMessage } = useWebChat();
  const { closePasswordReset } = usePasswordReset();



  const onSubmit = async () => {
    let hasError = false;

    if (!isUsernameValid(username)) {
      setIsUsernameValidationVisible(true);
      hasError = true;
    } else {
      setIsUsernameValidationVisible(false);
    }

    if (!isDobValid(dob)) {
      setIsDobValidationVisible(true);
      hasError = true;
    } else {
      setIsDobValidationVisible(false);
    }

    if (hasError) {
      return;
    }

    const token = await captchaRef.current.executeAsync();
    //const token = '';
    sendActivity({
      type: 'message',
      value: {
        username: username,
        dob: dob,
        captchaToken: token,
        type: 'forgotPasswordCardClick',
        intentName: 'forgotPassword'
      }
    });
    setIsCaptchaHidden(false);
    setIsSubmitted(true);
  };

  const onForgotUsername = () => {
    sendEventToDataLayer({
      event: "chatbot",
      eventDetails: {
        category: "CiCi",
        action: "Password Reset Flow",
        label: "'Forgot Username' Link Click"
      }
    });
    // postActivity({
    //   type: 'message',
    //   value: {
    //     intentName: 'forgotUsername'
    //   }
    // });
    setIsSubmitted(true);
    const message = currentLanguage === "en" ? "Forgot my username" : "Olvidé mi nombre de usuario";
    closePasswordReset();
        console.log("closePasswordReset");
        setTimeout(() => {
            //console.log(`Sending message: ${message}`);
            sendMessage(message);
        }, 1000);
  };

  const onCaptchaChange = (newCaptcha) => {
    setCaptchaToken(newCaptcha);
    updateSubmitButtonState(username, dob, newCaptcha);
  };

  const isUsernameValid = (username) => {
    if (!username || username === "") {
      return false;
    }
    return true;
  };

  const isDobValid = (dob) => {
    if (dob.trim().length === 0 || dob === "__/__") {
        return false;
    }
    const [month, day] = dob.split('/');
    const parsedMonth = parseInt(month, 10);
    const parsedDay = parseInt(day, 10);
    
    if (isNaN(parsedMonth) || isNaN(parsedDay)) {
        return false;
    }
    
    // Ensure month is between 1 and 12, and day is between 1 and 31
    if (parsedMonth < 1 || parsedMonth > 12 || parsedDay < 1 || parsedDay > 31) {
        return false;
    }

    return true;
};

  const areInputsValid = (username, dob) => {
    if (!isUsernameValid(username)) {
      return false;
    }
    if (!isDobValid(dob)) {
      return false;
    }
    return true;
  };

  const updateSubmitButtonState = (username, dob, captcha) => {
    if (areInputsValid(username, dob)) {
      setIsSubmitDisabled(false);
    } else {
      setIsSubmitDisabled(true);
    }
  };

  const onUsernameUpdate = (event) => {
    const newUsername = event.target.value;
    setUsername(newUsername);
    if (!isUsernameValid(newUsername)) {
      setIsUsernameValidationVisible(true);
    } else {
      setIsUsernameValidationVisible(false);
    }
    updateSubmitButtonState(newUsername, dob, captchaToken);
  };

  const onDobUpdate = (event) => {
    const newDob = event.target.value;
    setDob(newDob);


    if (newDob.length === 5 && !newDob.includes('_')) { 
        if (!isDobValid(newDob)) {
            setIsDobValidationVisible(true);
        } else {
            setIsDobValidationVisible(false);
        }
    } else {
        setIsDobValidationVisible(false);
    }

    updateSubmitButtonState(username, newDob, captchaToken);
};




  useEffect(() => {
    localStorage.setItem("conversationMayContainPii", true);
  }, []);


  const linkStyle = {
    color: 'var(--CalHEERS-Main-Blue, #2D77A3)',
    fontFamily: 'Open Sans',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '20px',
    textDecorationLine: 'underline'
};



useEffect(() => {
  const focusAlert = () => {
    if (alertRef.current) {
      alertRef.current.focus();
      alertRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
    } else {
      setTimeout(focusAlert, 300); 
    }
  };

  focusAlert();
}, [props.exceededAttemptMessage, props.userNotFound, props.serviceNotAvailable]);


return (
  <>
    <WizardLayout.Content>
      <PageLayout>
        <PageLayout.Header style={{ marginBottom: "8px" }}>
          <SubHeader subHeader={AuthenticationPageTranslations.header} />
        </PageLayout.Header>
        <PageLayout.Content>
          <Body
            bodyText={
              <>
                <div style={{ marginTop: '0px' }}>
                {AuthenticationPageTranslations.subHeader}
                </div>
              </>
            }
          />
          <Form>
            <Form.FormGroup>
              <Form.Label>{AuthenticationPageTranslations.username}</Form.Label>
              <Form.Control>
                {/* <Input
                  onChange={onUsernameUpdate}
                  value={username}
                  placeholder="Enter username"
                /> */}
                 <Input
                    style={isUsernameValidationVisible ? { border: '1px solid red' } : {}}
                    onChange={onUsernameUpdate}
                    value={username}
                    placeholder={AuthenticationPageTranslations.userNamePlaceholder}
                  />
                {isUsernameValidationVisible && <InputValidationMessage>{AuthenticationPageTranslations.enterUsername}</InputValidationMessage>}
              </Form.Control>
              <a href="#!" style={linkStyle} onClick={onForgotUsername} disabled={isSubmitted}>{AuthenticationPageTranslations.forgotUsername}</a>
            </Form.FormGroup>
            <Form.FormGroup>
              <Form.Label>{AuthenticationPageTranslations.dateOfBirth}</Form.Label>
              <Form.Control>
                <InputMask
                 style={isDobValidationVisible ? { border: '1px solid red' } : {paddingLeft: '10px'}}
                  mask="99/99"
                  maskChar="_"
                  className="form-control"
                  placeholder={AuthenticationPageTranslations.dateOfBirthPlaceholder}
                  onChange={onDobUpdate}
                  value={dob}
                />
              </Form.Control>
              {isDobValidationVisible && <InputValidationMessage>{AuthenticationPageTranslations.validationMessage}</InputValidationMessage>}
            </Form.FormGroup>
          </Form>

          <div className="d-none">
            <ReCAPTCHA
              sitekey={CAPTCHA_SITE_KEY}
              ref={captchaRef}
              size="invisible"
            />
          </div>
          <div className="my-3">
          <a href="#!" style={linkStyle} onClick={() => setShowPrivacyModal(true)}>{AuthenticationPageTranslations.privacyStatement}</a>
            {/* </div> */}
          </div>
          {props.exceededAttemptMessage && (
             <div
             ref={(ref) => {
               if (ref) {
                 alertRef.current = ref;
                 ref.focus();
                 ref.scrollIntoView({ behavior: "smooth", block: "center" });
               }
             }}
             tabIndex={-1}
           >
             <Alert
               iconType="red"
               frameIcon="red"
               message={props.exceededAttemptMessage || props.userNotFound || props.serviceNotAvailable}
             />
           </div>
           
              
            )}
            {props.userNotFound && (
              <div ref={alertRef} tabIndex={-1}>
                <Alert
                  iconType='red'
                  frameIcon='red'
                  message={props.userNotFound}
                />
              </div>
            )}
            {props.serviceNotAvailable && (
              <div ref={alertRef} tabIndex={-1}>
                <Alert
                  iconType='red'
                  frameIcon='red'
                  message={props.serviceNotAvailable}
                />
              </div>
            )}
        </PageLayout.Content>
      </PageLayout>
    </WizardLayout.Content>
    <WizardLayout.Navigation>
      <NavigationButton onClick={onSubmit} type={"primary"}  disabled={isSubmitDisabled || isSubmitted}
      >
        {AuthenticationPageTranslations.submit}
      </NavigationButton>
    </WizardLayout.Navigation>
    {showPrivacyModal && (
                <PrivacyStatementModal type="fixed" insideWizard={true} handleClose={() => setShowPrivacyModal(false)} />
            )}
  </>
);
}

export default ResetPasswordPage;