import React from "react";
import PageLayout from "../../UiKit/Wizard/Page/PageLayout";
import NavigationButton from "../../UiKit/Wizard/NavigationButton/NavigationButton";
import SubHeader from "../../UiKit/Wizard/SubHeader/SubHeader";
import Body from "../../UiKit/Wizard/Body/Body";
import usePasswordReset from "../../Hooks/usePasswordReset";
import { useLanguage } from "../../UiKit/Wizard/langugeContext";
import useChatbot from "../../Hooks/useChatbot";
import Lottie from "react-lottie";
import cicijson from "../../Images/CiCi-Celebrating-Wider.json";
import { translations } from "../../Components/PasswordResetWizard/translations";

function SuccessPage(props) {
    const { closePasswordReset } = usePasswordReset();
    const { currentLanguage } = useLanguage();
    const { closePasswordResetWizard } = useChatbot();
    const successPageTranslations = translations[currentLanguage]["SuccessPage"];

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: cicijson,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    const loginUrl = currentLanguage === "en"
        ? "https://apply.coveredca.com/static/lw-web/login"
        : "https://apply.coveredca.com/static/lw-web/login?lang=es_ES";

    return (
        <PageLayout>
            <PageLayout.Header>
                <SubHeader subHeader={successPageTranslations.subHeader} />
            </PageLayout.Header>
            <PageLayout.Content>
                <Body style={{ fontSize: "16px" }}
                    bodyText={
                        <>
                            <div style={{ marginTop: '10px' }}>
                                {successPageTranslations.bodyTextPart1}{" "}
                                <a href={loginUrl} target="_blank" rel="noopener noreferrer">
                                    {successPageTranslations.loginLinkText}
                                </a>
                                {successPageTranslations.bodyTextPart2}                                
                            </div>
                        </>
                    }
                />
                <div style={{ textAlign: "center", marginTop: "32px" }}>
                    <Lottie
                        options={defaultOptions}
                        height={112}
                        width={288}
                    />
                </div>
            </PageLayout.Content>
            <PageLayout.Navigation>
                <NavigationButton 
                    onClick={() => closePasswordResetWizard()}
                    type="primary" style={{ marginBottom: "36px" }}
                >
                    {successPageTranslations.exit}
                </NavigationButton>
            </PageLayout.Navigation>
        </PageLayout>
    );
}

export default SuccessPage;
