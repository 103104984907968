class UserSession {
  constructor(browserStorage, jwtDecoder) {
    this.browserStorage = browserStorage;
    this.jwtDecoder = jwtDecoder;
  }

  isNewUser = () => {
    const userSessionDataString =
      this.browserStorage.getItem("userSessionData");
    if (userSessionDataString === null) {
      return true;
    }

    const userSessionData = JSON.parse(userSessionDataString);
    if (!userSessionData.directlineToken) {
      return true;
    }

    if (!userSessionData.directlineToken.token) {
      return true;
    }

    if (!userSessionData.directlineToken.conversationId) {
      return true;
    }

    return false;
  };

  setLastActivity = (action) => {
    if (!action) return;
    if (!action.payload) return;
    if (!action.payload.activity) return;
    if (!action.payload.activity.id) return;

    const userSessionDataString =
      this.browserStorage.getItem("userSessionData");
    if (userSessionDataString === null) {
      throw new Error("user session data is not present");
    }
    const userSessionData = JSON.parse(userSessionDataString);
    this.browserStorage.setItem(
      "userSessionData",
      JSON.stringify({
        ...userSessionData,
        lastActivity: action.payload.activity,
      })
    );
  };

  getLastActivity = () => {
    const userSessionDataString =
      this.browserStorage.getItem("userSessionData");
    if (userSessionDataString === null) {
      return null;
    }
    const userSessionData = JSON.parse(userSessionDataString);

    if (!userSessionData.lastActivity) return null;

    return userSessionData.lastActivity;
  };

  setContainsPii = (containsPii) => {
    const userSessionDataString =
      this.browserStorage.getItem("userSessionData");
    if (userSessionDataString === null) {
      throw new Error("user session data is not present");
    }
    const userSessionData = JSON.parse(userSessionDataString);
    this.browserStorage.setItem(
      "userSessionData",
      JSON.stringify({
        ...userSessionData,
        containsPii
      })
    );
  }

  isOfflineDirectLine = (directlineObject) => {
    if (!directlineObject) return false;
    if (directlineObject.webSocket) return false;
    return true;
  }

  update = ({token, directlineObject, language}) => {
    if (this.isOfflineDirectLine(directlineObject)) {
      this.browserStorage.setItem(
        "userSessionData",
        JSON.stringify({
          directlineToken: token,
          language,
          lastActivity: null,
          startActivity: null,
        })
      );
      return;
    }
    if (this.isNewUser()) {
      this.browserStorage.setItem(
        "userSessionData",
        JSON.stringify({
          directlineToken: token,
          language,
          lastActivity: null,
          startActivity: null,
        })
      );
      return;
    }

    const userSessionDataString =
      this.browserStorage.getItem("userSessionData");
    const userSessionData = JSON.parse(userSessionDataString);

    if (userSessionData.directlineToken.conversationId !== token.conversationId) {
      this.browserStorage.setItem(
        "userSessionData",
        JSON.stringify({
          directlineToken: token,
          language,
          lastActivity: null,
          startActivity: null,
        })
      );
      return;
    }

    this.browserStorage.setItem(
      "userSessionData",
      JSON.stringify({
        ...userSessionData,
        directlineToken: token,
      })
    );
  };

  getConversationId = () => {
    const userSessionDataString =
      this.browserStorage.getItem("userSessionData");
    if (userSessionDataString === null) {
      throw new Error("user session data is not present");
    }
    const userSessionData = JSON.parse(userSessionDataString);
    return userSessionData.directlineToken.conversationId;
  };

  getTokenExpiration = () => {
    const userSessionDataString =
      this.browserStorage.getItem("userSessionData");
    if (userSessionDataString === null) {
      return null;
    }
    const userSessionData = JSON.parse(userSessionDataString);
    return this.jwtDecoder(userSessionData.directlineToken.token).exp * 1000;
  };

  getWatermark = () => {
    const userSessionDataString =
      this.browserStorage.getItem("userSessionData");
    if (userSessionDataString === null) {
      throw new Error("user session data is not present");
    }
    const userSessionData = JSON.parse(userSessionDataString);
    if (userSessionData.lastActivity === null) return 0;
    const activityId = userSessionData.lastActivity.id;
    return parseInt(activityId.split("|")[1]);
  };

  previousSessionHasExpired = () => {
    const userSessionDataString =
      this.browserStorage.getItem("userSessionData");
    if (userSessionDataString === null) {
      return true;
    }
    const userSessionData = JSON.parse(userSessionDataString);
    const expirationTime = new Date(
      this.jwtDecoder(userSessionData.directlineToken.token).exp * 1000
    );
    const timeRightNow = new Date(Date.now());
    return expirationTime < timeRightNow;
  }

  createNewSession = (token) => {
    this.update(token)
  }

  getPreviousLanguage = () => {
    const userSessionDataString =
      this.browserStorage.getItem("userSessionData");
      if(!userSessionDataString) return null;
    const userSessionData = JSON.parse(userSessionDataString);
    if(!userSessionData) return null;
    return userSessionData.language;
  }

  resetSession = () => {
    this.browserStorage.removeItem("userSessionData");
  }

  containsPii = () => {
    const userSessionDataString = this.browserStorage.getItem("userSessionData");
    if(!userSessionDataString) return false;

    const userSessionData = JSON.parse(userSessionDataString);
    if(!userSessionData) return false;

    if(!userSessionData.containsPii) return false;

    return true;
  }
}

export default UserSession;
