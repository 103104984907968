import React, { useState, useEffect, useContext, useRef } from "react";
import PageLayout from "../../UiKit/Wizard/Page/PageLayout";
import NavigationButton from "../../UiKit/Wizard/NavigationButton/NavigationButton";
import usePasswordReset from "../../Hooks/usePasswordReset";
import SubHeader from "../../UiKit/Wizard/SubHeader/SubHeader";
import Form from "../../UiKit/Form/Form";
import Input from "../../UiKit/Wizard/Input/Input";
import WizardLayout from "../../UiKit/Wizard/WizardLayout/WizardLayout";
import Body from "../../UiKit/Wizard/Body/Body";
import Alert from "../../UiKit/Wizard/Alert/Alert";
import { PasswordResetContext } from "../../Contexts/PasswordResetContext";
import Tooltip from "../../UiKit/Wizard/Tooltip/Tooltip";
import useChatbot from "../../Hooks/useChatbot";
import styles from "../../UiKit/Wizard/WizardLayout/WizardLayout.module.css";
import { translations } from "../../Components/PasswordResetWizard/translations";
import { useLanguage } from "../../UiKit/Wizard/langugeContext";

function PasswordPage({ postActivity, sendActivity, onNext, passwordResetEvent, onPrevious, props }) {
  const { next, previous } = usePasswordReset();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [useExpiredToken, setUseExpiredToken] = useState(false);
  const [isUsedExpiredTokenVisible, setIsUsedExpiredTokenVisible] = useState(false);
  const [isPasswordValidationVisible, setIsPasswordValidationVisible] = useState(false);
  const [isConfirmPasswordValidationVisible, setIsConfirmPasswordValidationVisible] = useState(false);
  const { passwordInvalid, setPasswordInvalid } = useContext(PasswordResetContext);
  const { closePasswordResetWizard } = useChatbot();
  const { currentLanguage } = useLanguage();
  const passwordPageTranslations = translations[currentLanguage]["PasswordPage"];

  // References to the validation messages
  const passwordValidationRef = useRef(null);
  const confirmPasswordValidationRef = useRef(null);

  useEffect(() => {
    if (passwordResetEvent && passwordResetEvent.data) {
      setPasswordInvalid(passwordResetEvent.data.passwordInvalid || "");
    }
  }, [passwordResetEvent, setPasswordInvalid]);

  const hasUpperCase = (password) => password && /[A-Z]/.test(password);
  const hasLowerCase = (password) => password && /[a-z]/.test(password);
  const hasNumber = (password) => password && /\d/.test(password);
  const hasSpecialChar = (password) => password && /[~!@#$%^&*()_+\-=\[\]\\{}|;':",.\/<>?]/g.test(password);
  const isPasswordMatch = (password, confirmPassword) => password !== "" && password === confirmPassword;
  const isFiveToFiftyChars = (password) => password.length >= 8 && password.length <= 50;

  const getCriteriaClass = (criteriaCheckFunction, password) => criteriaCheckFunction(password) ? "text-success pb-0" : "pb-0";

  const onPasswordUpdate = (newPassword) => {
    setPassword(newPassword);
    
    if (!isFiveToFiftyChars(newPassword)) {
      setIsPasswordValidationVisible(true);
    } else {
      setIsPasswordValidationVisible(false);
    }

    // Check if confirm password has been entered before showing error
    if (confirmPassword.trim().length > 0) {
      if (isPasswordMatch(newPassword, confirmPassword)) {
        setIsConfirmPasswordValidationVisible(false);
      } else {
        setIsConfirmPasswordValidationVisible(true);
      }
    } else {
      setIsConfirmPasswordValidationVisible(false);
    }

  
    if (
      isFiveToFiftyChars(newPassword) &&
      hasUpperCase(newPassword) &&
      hasLowerCase(newPassword) &&
      hasNumber(newPassword) &&
      hasSpecialChar(newPassword) &&
      isPasswordMatch(newPassword, confirmPassword)
    ) {
      setIsSubmitDisabled(false);
    } else {
      setIsSubmitDisabled(true);
    }
  };

  const onConfirmPasswordUpdate = (newConfirmPassword) => {
    setConfirmPassword(newConfirmPassword);

 
    if (newConfirmPassword.trim().length > 0 && password.trim().length > 0) {
      if (!isPasswordMatch(password, newConfirmPassword)) {
        setIsConfirmPasswordValidationVisible(true);
      } else {
        setIsConfirmPasswordValidationVisible(false);
      }
    } else {
      setIsConfirmPasswordValidationVisible(false);
    }


    if (
      isFiveToFiftyChars(password) &&
      hasUpperCase(password) &&
      hasLowerCase(password) &&
      hasNumber(password) &&
      hasSpecialChar(password) &&
      isPasswordMatch(password, newConfirmPassword)
    ) {
      setIsSubmitDisabled(false);
    } else {
      setIsSubmitDisabled(true);
    }
  };

  const validateForm = () => {
    const isValid =
      isFiveToFiftyChars(password) &&
      hasUpperCase(password) &&
      hasLowerCase(password) &&
      hasNumber(password) &&
      hasSpecialChar(password) &&
      isPasswordMatch(password, confirmPassword);

    if (!isFiveToFiftyChars(password)) {
      setIsPasswordValidationVisible(true);
      if (passwordValidationRef.current) {
        passwordValidationRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
        passwordValidationRef.current.focus();
      }
      return false;
    } else {
      setIsPasswordValidationVisible(false);
    }

    if (!isPasswordMatch(password, confirmPassword)) {
      setIsConfirmPasswordValidationVisible(true);
      if (confirmPasswordValidationRef.current) {
        confirmPasswordValidationRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
        confirmPasswordValidationRef.current.focus();
      }
      return false;
    } else {
      setIsConfirmPasswordValidationVisible(false);
    }

    return isValid;
  };

  const onSubmit = () => {
    if (validateForm()) {
      sendActivity({
        type: "message",
        value: {
          type: "forgotPasswordCardClick",
          password: password,
          intentName: "forgotPassword",
          useExpiredToken: useExpiredToken,
        },
      });
      setIsDisabled(true);
      setIsSubmitDisabled(true);
    }
  };

  const liStyle = {
    color: "var(--CalHEERS-Primary-Text, #334048)",
    fontFamily: "Open Sans",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "140%",
    paddingBottom: "4px",
  };

  return (
    <>
      <WizardLayout.Content>
        <PageLayout>
          <PageLayout.Header style={{ marginBottom: "8px" }}>
            <SubHeader subHeader={passwordPageTranslations.subHeader} />
          </PageLayout.Header>
          <PageLayout.Content>
            <Body
              bodyText={
                <>
                  <div className="mb-0" style={{ color: "var(--CalHEERS-Primary-Text, #334048)", fontFamily: "Open Sans", fontSize: "12px", fontStyle: "normal", fontWeight: "700", lineHeight: "140%" }}>
                    {passwordPageTranslations.bodyText}
                  </div>
                  <ul style={{ paddingLeft: "20px", paddingBottom: "24px", marginTop: "6px" }}>
                    <li style={liStyle}>{passwordPageTranslations.passwordText1}</li>
                    <li style={liStyle}>{passwordPageTranslations.passwordText2}</li>
                    <li style={{ ...liStyle, marginBottom: "0" }}>{passwordPageTranslations.passwordText3}</li>
                  </ul>
                </>
              }
            />
            <Form style={{ marginTop: "-32px" }}>
              <Form.FormGroup>
                <Form.Label>{passwordPageTranslations.password}</Form.Label>
                <Form.Control>
                  <Input
                    disabled={isDisabled}
                    type="password"
                    onBlur={(e) => onPasswordUpdate(e.target.value)}
                    onChange={(e) => onPasswordUpdate(e.target.value)}
                    placeholder={passwordPageTranslations.passwordPlaceholder}
                  />
                </Form.Control>
                <ul style={{ paddingLeft: "20px", marginBottom: "24px", marginTop: "6px" }}>
                  <li style={liStyle} className={isFiveToFiftyChars(password) ? "text-success pb-0" : "pb-0"}>
                    {passwordPageTranslations.passwordValidation1}
                  </li>
                  <li style={liStyle} className={getCriteriaClass(hasUpperCase, password)}>{passwordPageTranslations.passwordValidation2}</li>
                  <li style={liStyle} className={getCriteriaClass(hasLowerCase, password)}>{passwordPageTranslations.passwordValidation3}</li>
                  <li style={liStyle} className={getCriteriaClass(hasNumber, password)}>{passwordPageTranslations.passwordValidation5}</li>
                  <li style={{ ...liStyle, marginBottom: "0", alignItems: "center" }} className={getCriteriaClass(hasSpecialChar, password)}>
                    {passwordPageTranslations.passwordValidation4}
                    <Tooltip width="147px" height="auto" message={passwordPageTranslations.tooltip} forcePosition="top" style={{ marginLeft: "8px" }} />
                  </li>
                </ul>
                {isPasswordValidationVisible && (
                  <div className="text-danger" ref={passwordValidationRef} tabIndex="-1">{passwordPageTranslations.passwordValidation6}</div>
                )}
              </Form.FormGroup>
              <Form.FormGroup>
                <Form.Label>{passwordPageTranslations.confirmPassword}</Form.Label>
                <Form.Control>
                  <Input
                    disabled={isDisabled}
                    type="password"
                    onBlur={(e) => onConfirmPasswordUpdate(e.target.value)}
                    onChange={(e) => onConfirmPasswordUpdate(e.target.value)}
                    placeholder={passwordPageTranslations.confirmPasswordPlaceholder}
                  />
                </Form.Control>
                {isConfirmPasswordValidationVisible && (
                  <div className="text-danger" ref={confirmPasswordValidationRef} tabIndex="-1">{passwordPageTranslations.validationPasswordMessage}.</div>
                )}
              </Form.FormGroup>
            </Form>
            {passwordInvalid && (
              <Alert iconType="red" frameIcon="red" message={passwordInvalid} />
            )}
          </PageLayout.Content>
        </PageLayout>
      </WizardLayout.Content>
      <div style={{ position: "relative" }}>
        <div className={`${styles.overlaybottom} ${styles['overlaybottom--passcode']}`}></div>
      </div>
      <WizardLayout.Navigation>
        <NavigationButton onClick={onSubmit} type={"primary"} disabled={isSubmitDisabled}>{passwordPageTranslations.changePassword}</NavigationButton>
        <NavigationButton onClick={closePasswordResetWizard} type={"secondary"}>{passwordPageTranslations.exit}</NavigationButton>
      </WizardLayout.Navigation>
    </>
  );
}

export default PasswordPage;
